import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger,SplitText)

const headings = document.querySelectorAll(' h2:not(.no-font-anim), h3, h4, h5, h6');

headings.forEach(heading => {
    // Set the initial font variation setting
    gsap.set(heading, {
        fontVariationSettings: '"EXPO" 10'
    });


    gsap.to(heading, {
        fontVariationSettings: '"EXPO" -80',
        ease: "none",
        scrollTrigger: {
            trigger: heading,
            start: "top bottom", // Start when the top of the heading enters the viewport
            end: "bottom top", // End when the bottom of the heading leaves the viewport
            scrub: true,
            onUpdate: self => {
                // Calculate progress based on element's position in the viewport
                const elementRect = heading.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const elementProgress = 1 - (elementRect.top / viewportHeight);

                // Map the progress to the font variation range (50 to -50)
                const fontVariationValue = gsap.utils.mapRange(0, 1, 50, -50, elementProgress);

                // Apply the font variation
                heading.style.fontVariationSettings = `"EXPO" ${fontVariationValue}`;
            }
        }
    });
});


document.addEventListener("DOMContentLoaded", function() {
    const h1 = document.querySelector('.hero-frontpage .text-content h1,.hero h1');

    if (h1) {
        // Set initial state
        gsap.set(h1, {
            opacity: 0,
            y: 20,
            fontVariationSettings: '"EXPO" 50'
        });

        // Animate the h1
        gsap.to(h1, {
            duration: 1.5,
            opacity: 1,
            y: 0,
            fontVariationSettings: '"EXPO" -20',
            ease: "power3.out",
            delay: 0.5 
        });
    }
});